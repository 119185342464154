import React from "react";
import Down from "../assets/down.png";
import Right from "../assets/right.png";
import Left from "../assets/left.png";

function Blocks() {
  return (
    <div className="bg-white text-black w-full h-full p-8 md:p-0 lg:p-0">
      <div className="flex flex-col h-full">
        <div className="grid grid-cols-1">
          <div className="grid grid-cols-1 gap-0 flex">
            <div className="bg-white flex flex-col justify-center items-center h-full flex-grow-1 md:mt-10 lg:mt-10">
              <h1 className="text-center text-2xl md:text-3xl lg:text-4xl font-bold py-2 hover:scale-105 transition duration-300">
                Na co se dají naše fólie použít?
              </h1>
              <div className="grid grid-cols-7 gap-0 flex">
                <div className="col-span-5 col-start-2 lg:col-start-3 lg:col-span-3">
                  <p className="text-sm md:text-md lg:text-md text-center">
                    Stretch fólie mají specifické použití především při balení
                    paletového zboží a fixaci jednotlivých objektů díky své
                    elastické a flexibilní povaze, která umožňuje pevné a
                    stabilní uchycení zboží, což účinně chrání před nežádoucím
                    pohybem, otřesy a poškozením během přepravy.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="grid grid-cols-7 gap-0 flex h-full">
            <div className="hidden lg:block col-start-5 col-span-2 mt-10">
              <img
                src={Down}
                alt="Arrow"
                className="w-48 hover:scale-105 transition duration-300"
              />
            </div>
          </div>
        </div>
        <div className="grid grid-cols-2 gap-0 flex mt-10">
          <div className="hidden lg:block col-span-1 my-10 mx-10">
            <div className="grid grid-cols-7 gap-0 flex h-full">
              <div className="col-start-6 col-span-2 mt-10">
                <img
                  src={Left}
                  alt="Arrow"
                  className="w-48 hover:scale-105 transition duration-300"
                />
              </div>
            </div>
          </div>
          <div className="col-span-2 lg:col-span-1">
            <div className="grid grid-cols-6 gap-0 flex">
              <div className="col-span-4 col-start-2 lg:col-span-3 lg:col-start-1">
                <h1 className="text-sm md:text-md lg:text-lg font-bold py-2 hover:scale-105 transition duration-300">
                  Naše fólie tloušťky{" "}
                  <span className="text-[#BB1F24]">7µm</span> nahrazují běžné
                  fólie do <span className="text-[#BB1F24]">14µm</span>
                </h1>
                <hr className="mb-4"></hr>
                <h2 className="text-sm md:text-md lg:text-md font-bold">
                  Tyto fólie se mohou použít na:
                </h2>
                <ul className="list-disc pl-4 text-sm">
                  <li className="my-2">balení lehkých palet</li>
                  <li className="my-2">zboží v kartonových krabicích</li>
                  <li className="my-2">květiny</li>
                  <li className="my-2">pečivo</li>
                  <li className="my-2">toaletní papír</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div className="grid grid-cols-2 gap-0 flex mt-10">
          <div className="col-span-2 lg:col-span-1">
            <div className="grid grid-cols-6 gap-0 flex">
              <div className="col-span-4 col-start-2 lg:col-span-3 lg:col-start-4">
                <h1 className="text-sm md:text-md lg:text-lg font-bold py-2 hover:scale-105 transition duration-300">
                  Naše fólie tloušťky{" "}
                  <span className="text-[#BB1F24]">9µm</span> nahrazují běžné
                  fólie do <span className="text-[#BB1F24]">20µm</span>
                </h1>
                <hr className="mb-4"></hr>
                <h2 className="text-sm md:text-md lg:text-md font-bold">
                  Tyto fólie se mohou použít na:
                </h2>
                <ul className="list-disc pl-4 text-sm">
                  <li className="my-2">balení středně těžkých palet</li>
                  <li className="my-2">zboží v boxech</li>
                  <li className="my-2">zelenina</li>
                  <li className="my-2">maso</li>
                  <li className="my-2">elektronika</li>
                </ul>
              </div>
            </div>
          </div>
          <div className="hidden lg:block col-span-1 my-10 mx-10">
            <div className="grid grid-cols-7 gap-0 flex h-full">
              <div className="col-start-1 col-span-2 mt-10">
                <img
                  src={Right}
                  alt="Arrow"
                  className="w-48 hover:scale-105 transition duration-300"
                />
              </div>
            </div>
          </div>
        </div>
        <div className="grid grid-cols-2 gap-0 flex mt-10">
          <div className="hidden lg:block col-span-1 my-10 mx-10">
            <div className="grid grid-cols-7 gap-0 flex h-full">
              <div className="col-start-6 col-span-2 mt-10">
                <img
                  src={Left}
                  alt="Arrow"
                  className="w-48 hover:scale-105 transition duration-300"
                />
              </div>
            </div>
          </div>
          <div className="col-span-2 lg:col-span-1">
            <div className="grid grid-cols-6 gap-0 flex">
              <div className="col-span-4 col-start-2 lg:col-span-3 lg:col-start-1">
                <h1 className="text-sm md:text-md lg:text-lg font-bold py-2 hover:scale-105 transition duration-300">
                  Naše fólie tloušťky{" "}
                  <span className="text-[#BB1F24]">11µm</span> nahrazují běžné
                  fólie do <span className="text-[#BB1F24]">25µm</span>
                </h1>
                <hr className="mb-4"></hr>
                <h2 className="text-sm md:text-md lg:text-md font-bold">
                  Tyto fólie se mohou použít na:
                </h2>
                <ul className="list-disc pl-4 text-sm">
                  <li className="my-2">balení těžkých palet</li>
                  <li className="my-2">tepelné a zvukové izolace</li>
                  <li className="my-2">mražené výrobky</li>
                  <li className="my-2">nábytek</li>
                  <li className="my-2">sklo</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div className="grid grid-cols-2 gap-0 flex mt-10">
          <div className="col-span-2 lg:col-span-1">
            <div className="grid grid-cols-6 gap-0 flex">
              <div className="col-span-4 col-start-2 lg:col-span-3 lg:col-start-4">
                <h1 className="text-sm md:text-md lg:text-lg font-bold py-2 hover:scale-105 transition duration-300">
                  Naše fólie tloušťky{" "}
                  <span className="text-[#BB1F24]">13µm</span> nahrazují běžné
                  fólie do <span className="text-[#BB1F24]">26µm</span>
                </h1>
                <hr className="mb-4"></hr>
                <h2 className="text-sm md:text-md lg:text-md font-bold">
                  Tyto fólie se mohou použít na:
                </h2>
                <ul className="list-disc pl-4 text-sm">
                  <li className="my-2">balení velmi těžkých palet</li>
                  <li className="my-2">zboží s ostrými hranami</li>
                  <li className="my-2">stavební materiál</li>
                  <li className="my-2">dlažby</li>
                  <li className="my-2">autodíly</li>
                </ul>
              </div>
            </div>
          </div>
          <div className="hidden lg:block col-span-1 my-10 mx-10">
            <div className="grid grid-cols-7 gap-0 flex h-full"></div>
          </div>
        </div>
        <div className="mt-10 lg:mt-20"></div>
      </div>
    </div>
  );
}

export default Blocks;
