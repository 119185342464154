import React from "react";
import Navbar from "../components/Navbar.jsx";
import Footer from "../components/Footer.jsx";
import Gdpr from "../components/Gdpr.jsx";

function GdprF() {
  return (
    <div>
      <Navbar />
      <Gdpr />
      <Footer />
    </div>
  );
}

export default GdprF;
