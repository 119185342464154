import React from "react";
import Typed from "react-typed";

function handleScroll(e) {
  e.preventDefault(); // Prevent the default anchor link behavior

  const targetId = e.target.getAttribute("href").substring(1);
  const targetElement = document.getElementById(targetId);
  const scrollContainer = document.documentElement;

  // Scroll to the target element using smooth behavior
  scrollContainer.scrollTo({
    top: targetElement.offsetTop,
    behavior: "smooth",
  });

  // Disable smooth scroll behavior after a brief delay
  setTimeout(() => {
    scrollContainer.style.scrollBehavior = "auto";
  }, 500); // Adjust the delay time as needed
}

const Hero = () => {
  return (
    <div className="text-white bg-hero-image bg-cover bg-center">
      <div className="max-w-[800px] mt-[-96px] w-full h-screen mx-auto text-center flex flex-col justify-center">
        <p className="text-[#BB1F24] text-xl font-bold">
          Vítejte na stránkách společnosti CASTOREA s.r.o.
        </p>
        <h1 className="md:text-7xl sm:text-6xl text-4xl font-bold md:py-6">
          Nebojte se inovace a udržitelnosti
        </h1>
        <div className="flex justify-center items-center md:text-4xl sm:text-3xl text-xl font-bold p-2">
          <p>U nás najdete</p>
          <Typed
            className="md:pl-4 pl-2 text-[#BB1F24]"
            strings={[
              "fixační fólie",
              "lepící pásky",
              "bublinkové fólie",
              "kartonové obaly",
              "lepenkové role",
            ]}
            typeSpeed={120}
            backSpeed={140}
            loop
          />
        </div>
        <a
          href="#analytics"
          className="bg-[#BB1F24] w-[200px] rounded-md font-medium my-6 mx-auto py-3 text-white hover:scale-105 transition duration-300"
        >
          Chci vědět více
        </a>
      </div>
    </div>
  );
};

export default Hero;
