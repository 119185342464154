import React from "react";
import Foil from "../assets/foil.png";
import Tape from "../assets/tape.png";
import Ostatni from "../assets/ostatni.png";
import { useEffect, useRef, useState } from "react";

const Cards = () => {
  const [inView, setInView] = useState(false);
  const ref = useRef(null);

  useEffect(() => {
    const handleScroll = () => {
      const { top } = ref.current.getBoundingClientRect();
      const windowHeight =
        window.innerHeight || document.documentElement.clientHeight;
      if (top <= windowHeight * 0.9) {
        setInView(true);
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const containerClassName = `transition-transform duration-1000 ease-in-out ${
    inView ? "scale-x-100" : "scale-x-0"
  }`;

  return (
    <div className="w-full py-[10rem] px-4 bg-white">
      <div
        className={`${containerClassName} transform-gpu origin-left`}
        ref={ref}
      >
        <div className="max-w-[1240px] mx-auto grid md:grid-cols-3 gap-8">
          <div className="w-full shadow-xl flex flex-col p-4 my-4 rounded-lg hover:scale-105 duration-300">
            <img
              className="w-20 mx-auto mt-[-3rem] bg-white"
              src={Foil}
              alt="/"
            />
            <p className="text-center text-4xl font-bold mt-6">Fixační fólie</p>
            <div className="text-center font-medium">
              <p className="py-2 border-b mx-8 mt-8">Extrémně pevná</p>
              <p className="py-2 border-b mx-8">Velmi tenká</p>
              <p className="py-2 border-b mx-8">Ekologicky šetrná</p>
            </div>
            <a className="mx-auto" href="/produkty" rel="noopener noreferrer">
              <button className="bg-[#BB1F24] text-white w-[200px] rounded-md font-medium my-6 mx-auto px-6 py-3 hover:scale-105 transition duration-300">
                Detail
              </button>
            </a>
          </div>
          <div className="w-full shadow-xl bg-white flex flex-col p-4 md:my-0 my-8 rounded-lg hover:scale-105 duration-300">
            <img
              className="w-20 mx-auto mt-[-3rem] bg-transparent"
              src={Tape}
              alt="/"
            />
            <p className="text-center text-4xl font-bold mt-6"> Lepící pásky</p>
            <div className="text-center font-medium">
              <p className="py-2 border-b mx-8 mt-8">Lepidlo HOTMELT</p>
              <p className="py-2 border-b mx-8">Extrémně přilnavá</p>
              <p className="py-2 border-b mx-8">Transparentní</p>
            </div>
            <a className="mx-auto" href="/produkty" rel="noopener noreferrer">
              <button className="bg-[#BB1F24] text-white w-[200px] rounded-md font-medium my-6 mx-auto px-6 py-3 hover:scale-105 transition duration-300">
                Detail
              </button>
            </a>
          </div>
          <div className="w-full shadow-xl flex flex-col p-4 my-4 rounded-lg hover:scale-105 duration-300 ">
            <img
              className="w-20 mx-auto mt-[-3rem] bg-white"
              src={Ostatni}
              alt="/"
            />
            <p className="text-center text-4xl font-bold mt-6">Ostatní obaly</p>
            <div className="text-center font-medium">
              <p className="py-2 border-b mx-8 mt-8">Bublinkové fólie</p>
              <p className="py-2 border-b mx-8">Kartonové obaly</p>
              <p className="py-2 border-b mx-8">Lepenkové role</p>
            </div>
            <a className="mx-auto" href="/produkty" rel="noopener noreferrer">
              <button className="bg-[#BB1F24] text-white w-[200px] rounded-md font-medium my-6 mx-auto px-6 py-3 hover:scale-105 transition duration-300">
                Detail
              </button>
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Cards;
