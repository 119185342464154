import React from "react";
import Navbar from "../components/Navbar.jsx";
import Footer from "../components/Footer.jsx";
import Cookies from "../components/Cookies.jsx";

function CookiesF() {
  return (
    <div>
      <Navbar />
      <Cookies />
      <Footer />
    </div>
  );
}

export default CookiesF;
