import React from "react";
import Navbar from "../components/Navbar.jsx";
import Footer from "../components/Footer.jsx";
import Block from "../components/Blocks.jsx";

function Pouziti() {
  return (
    <div>
      <Navbar />
      <Block />
      <Footer />
    </div>
  );
}

export default Pouziti;
