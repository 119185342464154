import React from "react";
import CardBox from "../assets/card-box.png";
import StrojniFixacniFolie from "../assets/strojni-fixacni-folie.png";
import RucniFixacniFolie from "../assets/rucni-fixacni-folie.png";
import LepiciPaska from "../assets/lepici-pasky.png";

const Squares = () => {
  return (
    <div className="w-full lg:h-screen bg-white">
      <div class="col-span-full text-center pt-16 pb-8">
        <h1 class="font-dmserif text-3xl font-bold mb-3 ">Produkty</h1>
        <p class="text-lg italic mb-5">
          Zjistěte více o nabídce našich produktů.
        </p>
      </div>
      <div class="flex items-center justify-center">
        <div class="grid grid-cols-1 gap-5 md:grid-cols-2 lg:grid-cols-4 pt-8 pb-40">
          <div class="group relative cursor-pointer items-center justify-center overflow-hidden transition-shadow hover:shadow-xl hover:shadow-black/30">
            <div class="h-96 w-72">
              <img
                class="h-full w-full object-cover transition-transform duration-500 group-hover:rotate-3 group-hover:scale-125"
                src={RucniFixacniFolie}
                alt=""
              />
            </div>
            <div class="absolute inset-0 bg-gradient-to-b from-transparent via-transparent to-black group-hover:from-black/70 group-hover:via-black/60 group-hover:to-black/70"></div>
            <div class="absolute inset-0 flex translate-y-[60%] flex-col items-center justify-center px-9 text-center transition-all duration-500 group-hover:translate-y-0">
              <h1 class="font-dmserif text-3xl font-bold text-white">
                Ruční fixační fólie
              </h1>
              <p class="mb-3 text-lg italic text-white opacity-0 transition-opacity duration-300 group-hover:opacity-100">
                Je to polyethylenová fólie na roli určená pro ruční balení a
                fixaci předmětů, umožňující pevné a bezpečné obalování.
              </p>
            </div>
          </div>
          <div class="group relative cursor-pointer items-center justify-center overflow-hidden transition-shadow hover:shadow-xl hover:shadow-black/30">
            <div class="h-96 w-72">
              <img
                class="h-full w-full object-cover transition-transform duration-500 group-hover:rotate-3 group-hover:scale-125"
                src={StrojniFixacniFolie}
                alt=""
              />
            </div>
            <div class="absolute inset-0 bg-gradient-to-b from-transparent via-transparent to-black group-hover:from-black/70 group-hover:via-black/60 group-hover:to-black/70"></div>
            <div class="absolute inset-0 flex translate-y-[60%] flex-col items-center justify-center px-9 text-center transition-all duration-500 group-hover:translate-y-0">
              <h1 class="font-dmserif text-3xl font-bold text-white">
                Strojní fixační fólie
              </h1>
              <p class="mb-3 text-lg italic text-white opacity-0 transition-opacity duration-300 group-hover:opacity-100">
                Je to polyethylenová fólie pro použití ve stojacích strojích a
                automatických balicích linkách k fixaci a stabilizaci zboží při
                přepravě a skladování.
              </p>
            </div>
          </div>
          <div class="group relative cursor-pointer items-center justify-center overflow-hidden transition-shadow hover:shadow-xl hover:shadow-black/30">
            <div class="h-96 w-72">
              <img
                class="h-full w-full object-cover transition-transform duration-500 group-hover:rotate-3 group-hover:scale-125"
                src={LepiciPaska}
                alt=""
              />
            </div>
            <div class="absolute inset-0 bg-gradient-to-b from-transparent via-transparent to-black group-hover:from-black/70 group-hover:via-black/60 group-hover:to-black/70"></div>
            <div class="absolute inset-0 flex translate-y-[60%] flex-col items-center justify-center px-9 text-center transition-all duration-500 group-hover:translate-y-0">
              <h1 class="font-dmserif text-3xl font-bold text-white">
                Lepící pásky
              </h1>
              <p class="mb-3 text-lg italic text-white opacity-0 transition-opacity duration-300 group-hover:opacity-100">
                Jsou to tenké pruhové materiály s lepicí vrstvou, které se
                používají pro spojování, balení a fixaci různých předmětů.
              </p>
            </div>
          </div>
          <div class="group relative cursor-pointer items-center justify-center overflow-hidden transition-shadow hover:shadow-xl hover:shadow-black/30">
            <div class="h-96 w-72">
              <img
                class="h-full w-full object-cover transition-transform duration-500 group-hover:rotate-3 group-hover:scale-125"
                src={CardBox}
                alt=""
              />
            </div>
            <div class="absolute inset-0 bg-gradient-to-b from-transparent via-transparent to-black group-hover:from-black/70 group-hover:via-black/60 group-hover:to-black/70"></div>
            <div class="absolute inset-0 flex translate-y-[60%] flex-col items-center justify-center px-9 text-center transition-all duration-500 group-hover:translate-y-0">
              <h1 class="font-dmserif text-3xl font-bold text-white">
                Další obalové technologie
              </h1>
              <p class="mb-3 text-lg italic text-white opacity-0 transition-opacity duration-300 group-hover:opacity-100">
                Zahrnují postupy a materiály pro bezpečnost, kvalitu, efektivní
                skladování, manipulaci a distribuci výrobků.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Squares;
