import React from "react";

const BlockOnas = () => {
  return (
    <div className="w-full bg-white py-16 px-4" id="analytics">
      <div className="max-w-[1240px] mx-auto grid md:grid-cols-5">
        <div className="col-start-2 col-span-3 text-center flex flex-col justify-center">
          <div>
            <h1 className="font-dmserif text-3xl font-bold mb-10 ">
              Zásady o použití cookies
            </h1>
            <div className="text-lg">
              <p className="mb-10">
                Cookies, což jsou malá množství dat, která naše servery posílají
                vašemu počítači a která umožňují lepší využití našich serverů a
                přizpůsobení jejich obsahu vašim potřebám.
              </p>
              <p className="mb-10">
                Cookies používá téměř každá internetová stránka na světě.
                Cookies zvyšují uživatelskou přívětivost opakovaně navštívené
                internetové stránky, a proto jsou pro vás užitečné. Pokud pro
                návštěvu našich internetových stránek použijete stejný počítač a
                stejný internetový prohlížeč, cookies pomáhají vašemu počítači
                zapamatovat si navštívené stránky a vaše nastavení stránek.
              </p>
              <p className="mb-10">
                Prostřednictvím našich webových stránek mohou být ve vašem
                počítači ukládány také cookies provozovatelů reklamních systémů,
                které jsou na našich stránkách provozovány. V rámci remarketingu
                využívá naše společnost také systémy Google. Data z remarketingu
                používáme výhradně k segmentaci návštěvníků za účelem doručení
                relevantnějšího reklamního sdělení. Segmenty jsou vytvořeny na
                základě několika obecných vzorců chování návštěvníků.
              </p>
              <p className="mb-10">
                Standardní webové prohlížeče (Safari, Internet Explorer,
                Firefox, Google Chrome apod.) podporují správu cookies. V rámci
                nastavení prohlížečů můžete jednotlivé cookies ručně mazat,
                blokovat či zcela zakázat jejich použití, lze je také blokovat
                nebo povolit jen pro jednotlivé internetové stránky. Pro
                detailnější informace prosím použijte nápovědu vašeho
                prohlížeče. Pokud bude mít váš prohlížeč použití cookies
                povoleno, budeme vycházet z toho, že souhlasíte s využíváním
                standardních cookies ze strany našich serverů.
              </p>
              <p className="mb-10">
                Cookies nevyužíváme k jiným než čistě technickým účelům,
                nespojujeme údaje získané díky nim s jakýmikoliv jinými údaji a
                pracujeme s cookies tak, že neumožňují identifikovat konkrétní
                osoby.
              </p>
              <p className="mb-10">
                Existují dočasné cookies a trvalé cookies. Dočasné jsou uloženy
                ve vašem počítači jen do ukončení prohlížeče. Dočasné cookies
                umožňují uchovávání informací při přecházení z jedné webové
                stránky na druhou a odstraňují potřebu opakovaného zadávání
                některých údajů. Trvalé cookies pomáhají identifikovat váš
                počítač, jestliže znovu navštívíte náš web, ale neumožňují
                jakkoliv identifikovat vás osobně. Trvalé cookies umožňují
                přizpůsobovat naše stránky vašim zájmům, ale nemůžeme jakkoliv
                identifikovat vás osobně a příslušná data ukládáme zcela
                anonymizovaně a nespojujeme je s jakýmikoliv jinými daty..
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BlockOnas;
