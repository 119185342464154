import React from "react";
import bgPeople from "../assets/bg-people.png";

const BlockOnas = () => {
  return (
    <div className="w-full bg-white py-16 px-4" id="analytics">
      <div className="max-w-[1240px] mx-auto grid md:grid-cols-2">
        <div className="flex flex-col justify-center">
          <div>
            <h1 className="font-dmserif text-3xl font-bold mb-10 ">O nás</h1>
            <div className="text-lg">
              <p className="mb-10">
                Jsme poskytovatelem fixačních fólií, lepících pásek a
                inovativních obalových technologií. Dodáváme kvalitní produkty a
                služby dle průmyslových standardů.
              </p>
              <p className="mb-10">
                Před poskytnutím cenové nabídky provedeme důkladný výpočet vaší
                současné spotřeby obalových materiálů a provedeme technickou
                zkoušku pro objektivní srovnání s našimi výrobky.
              </p>
              <p className="mb-10">
                Pokud máte jakékoliv otázky, zájem o naše produkty nebo služby,
                neváhejte nás kontaktovat. Náš přátelský a vstřícný tým vám rád
                poskytne veškeré informace a pomůže vám najít optimální řešení
                pro vaše potřeby.
              </p>
            </div>
          </div>
          <a
            href="/kontakt"
            rel="noopener noreferrer"
            className="text-center bg-[#BB1F24] text-white w-[200px] rounded-md font-medium my-6 md:mx-0 mx-auto py-3 hover:scale-105 transition duration-300"
          >
            Kontakt
          </a>
        </div>
        <div className="flex items-center">
          <img className="w-full mx-auto my-4" src={bgPeople} alt="People" />
        </div>
      </div>
    </div>
  );
};

export default BlockOnas;
