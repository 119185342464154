import React from "react";
import Navbar from "../components/Navbar.jsx";
import Footer from "../components/Footer.jsx";
import BlockOnas from "../components/BlockOnas.jsx";

function Onas() {
  return (
    <div>
      <Navbar />
      <BlockOnas />
      <Footer />
    </div>
  );
}

export default Onas;
