import React from "react";
import { useEffect, useRef, useState } from "react";

const Newsletter = () => {
  return (
    <div className="w-full bg-white text-center py-16 text-black px-4">
      <div className="max-w-[1240px] mx-auto">
        <div className="my-4 text-center">
          <div>
            <h1 className="md:text-4xl sm:text-3xl text-2xl font-bold py-2">
              Chcete si objednat?
            </h1>
            <p>
              Věříme, že každý projekt je jedinečný a vyžaduje individuální
              přístup. Porozumění vašim konkrétním potřebám je pro nás prioritou
              a chceme se ujistit, že vám poskytneme nejlepší možnou cenovou
              nabídku. A proto jsme zvolili flexibilní přístup a umožnili jsme
              Vám si objednat přímo na míru.
            </p>
            <p className="mt-6">
              Tímto přístupem vám nabízíme možnost komunikovat s naším týmem
              přímo a podrobněji se seznámit s Vašimi požadavky. Chápeme, že
              každý projekt může mít specifické parametry, jako je rozsah,
              množství, dodací termíny a další faktory, které ovlivňují konečnou
              cenu. Abychom Vám poskytli přesnou a konkurenceschopnou nabídku,
              je nezbytné mít tyto informace přesně uvedené. Prostřednictvím
              e-mailu nebo telefonu Vám můžeme nabídnout individuální
              poradenství, odpovědi na Vaše otázky a poskytnout Vám veškeré
              potřebné informace týkající se našich produktů a cen.
            </p>
          </div>
          <div className="mt-6">
            <div className="bg-[#BB1F24] w-[150px] rounded-md font-medium mx-auto py-3 text-white hover:scale-105 transition duration-300">
              <a href="/kontakt">Chci si objednat</a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Newsletter;
