import React from "react";
import Flexibility from "../assets/flexibility.png";
import Moisture from "../assets/moisture.png";
import Transparency from "../assets/transparency.png";
import Money from "../assets/money.png";
import Rucni from "../assets/rucni-fixacni-folie2.png";
import Strojni from "../assets/strojni-fixacni-folie2.png";

const Folie = () => {
  return (
    <div
      id="folie"
      className="w-full lg:h-screen text-center py-8 text-white px-4"
    >
      <div>
        <div className="max-w-[1240px] mx-auto">
          <div className="my-4 text-center">
            <div>
              <h1 className="text-white md:text-5xl sm:text-4xl text-3xl font-bold py-2">
                Fixační fólie
              </h1>
              <p className="text-sm md:text-md lg:text-lg">
                Stretch fólie jsou prostředek k fixaci zboží na paletách.
                Zajišťují bezpečnost při přepravě zboží k zákazníkům a chrání ho
                před poškozením a znečištěním při manipulaci ve skladu a při
                dopravě. Nabízíme širokou škálu stretch fólií a ty jsou tedy
                například ruční fixační fólie strojní fixační fólie.
              </p>
            </div>
            <div className="grid lg:grid-cols-2 gap-10 md:gap-20 lg:gap-40 text-center mt-8">
              <div className="col-span-1">
                <h2 className="text-[#BB1F24] text-sm md:text-lg lg:text-xl font-bold">
                  Ruční fixační fólie
                </h2>
                <p className="text-sm">
                  Ruční fixační fólie je tenká a průhledná fólie určená k fixaci
                  a zabalení předmětů. Je vyrobená z polyetylenu nebo jiného
                  plastu a je dodávána ve formě role.
                </p>
                <img
                  className="mx-auto mt-5 rounded-xl hover:scale-110 transition duration-300"
                  src={Rucni}
                  alt="Ruční fixační fólie"
                />
              </div>
              <div className="col-span-1">
                <h2 className="text-[#BB1F24] text-sm md:text-lg lg:text-xl font-bold">
                  Strojní fixační fólie
                </h2>
                <p className="text-sm">
                  Strojní fixační fólie je fólie pro balení a fixaci předmětů s
                  použitím automatických balicích strojů. Je vyrobená z plastu a
                  určená pro mechanické systémy balicích strojů.
                </p>
                <img
                  className="mx-auto mt-5 rounded-xl hover:scale-110 transition duration-300"
                  src={Strojni}
                  alt="Ruční fixační fólie"
                />
              </div>
            </div>
            <div className="grid lg:grid-cols-4 gap-16 md:gap-20 lg:gap-24 text-center mt-32">
              <div className="col-span-1">
                <div>
                  <img
                    className="w-10 md:w-14 lg:w-16 mx-auto mt-[-3rem] hover:scale-125 transition duration-300"
                    src={Flexibility}
                    alt="Flexibilita"
                  />
                </div>
                <h3 className="text-[#BB1F24] text-sm md:text-md lg:text-lg lg:mb-4 lg:mt-4 md:mb-3 md:mt-3 mb-2 mt-2">
                  Pružnost a flexibilita
                </h3>
                <p className="text-sm md:text-md lg-text-lg">
                  Je elastická a snadno se přizpůsobuje různým tvarům a
                  rozměrům. To umožňuje fixaci předmětů s různými tvary a
                  nerovnými povrchy.
                </p>
              </div>
              <div className="col-span-1">
                <div>
                  <img
                    className="w-10 md:w-14 lg:w-16 mx-auto mt-[-3rem] hover:scale-125 transition duration-300"
                    src={Moisture}
                    alt="Vlhkost"
                  />
                </div>
                <h3 className="text-[#BB1F24] text-sm md:text-md lg:text-lg lg:mb-4 lg:mt-4 md:mb-3 md:mt-3 mb-2 mt-2">
                  Ochrana před vlhkostí
                </h3>
                <p className="text-sm md:text-md lg-text-lg">
                  Poskytuje ochranu před poškozením a znehodnocením způsobeným
                  vlhkostí, prachem a vnějšími vlivy, což zvyšuje životnost a
                  kvalitu předmětů.
                </p>
              </div>
              <div className="col-span-1">
                <div>
                  <img
                    className="w-10 md:w-14 lg:w-16 mx-auto mt-[-3rem] hover:scale-125 transition duration-300"
                    src={Transparency}
                    alt="Transparentnost"
                  />
                </div>
                <h3 className="text-[#BB1F24] text-sm md:text-md lg:text-lg lg:mb-4 lg:mt-4 md:mb-3 md:mt-3 mb-2 mt-2">
                  Transparentnost
                </h3>
                <p className="text-sm md:text-md lg-text-lg">
                  Průhlednost fixačních folií usnadňuje identifikaci a vizuální
                  kontrolu balených předmětů, což je výhodné při skladování a
                  přepravě.
                </p>
              </div>
              <div className="col-span-1">
                <div>
                  <img
                    className="w-10 md:w-14 lg:w-16 mx-auto mt-[-3rem] hover:scale-125 transition duration-300"
                    src={Money}
                    alt="Ekonomická volba"
                  />
                </div>
                <h3 className="text-[#BB1F24] text-sm md:text-md lg:text-lg lg:mb-4 lg:mt-4 md:mb-3 md:mt-3 mb-2 mt-2">
                  Ekonomická volba
                </h3>
                <p className="text-sm md:text-md lg-text-lg">
                  Je cenově dostupná a minimalizuje náklady na poškození
                  předmětů při manipulaci, skladování a přepravě, což eliminuje
                  potřebu opakování balení.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Folie;
