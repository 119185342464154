import React from "react";
import Navbar from "../components/Navbar.jsx";
import Footer from "../components/Footer.jsx";
import Squares from "../components/Squares.jsx";
import Folie from "../components/Folie.jsx";
import Pasky from "../components/Pasky.jsx";
import Dalsi from "../components/Dalsi.jsx";
import ProduktyKontakt from "../components/ProduktyKontakt.jsx";

function Produkty() {
  return (
    <div>
      <Navbar />
      <Squares />
      <Folie />
      <Pasky />
      <Dalsi />
      <ProduktyKontakt />
      <Footer />
    </div>
  );
}

export default Produkty;
