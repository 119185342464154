import React from "react";
import Easy from "../assets/easy-to-use.png";
import Speed from "../assets/speed.png";
import Moisture2 from "../assets/moisture2.png";
import Demontaz from "../assets/disassembly.png";
import SpecialPasky from "../assets/special-pasky.png";
import ObecnePasky from "../assets/obecne-pasky.png";

const Pasky = () => {
  return (
    <div
      id="pasky"
      className="w-full lg:h-screen bg-white text-center py-8 text-black px-4"
    >
      <div>
        <div className="max-w-[1240px] mx-auto">
          <div className="my-4 text-center">
            <div>
              <h1 className="text-black md:text-5xl sm:text-4xl text-3xl font-bold py-2">
                Lepící pásky
              </h1>
              <p className="text-sm md:text-md lg:text-lg">
                Lepící pásky jsou všestrannými nástroji pro spojování,
                přichycování a fixaci různých materiálů, které mají širokou
                škálu použití a jsou běžně využívány v mnoha odvětvích, včetně
                stavebnictví, elektroniky, automobilového průmyslu,
                zdravotnictví, kosmetiky a v domácnostech, kde slouží k opravám,
                balení, kreativním projektům a mnoha dalším aplikacím.
              </p>
            </div>
            <div className="grid lg:grid-cols-2 gap-10 md:gap-20 lg:gap-40 text-center mt-8">
              <div className="lg:mx-48 md:mx-16 mx-2 col-span-2">
                <h2 className="text-[#BB1F24] text-sm md:text-lg lg:text-xl font-bold">
                  Obecné lepící pásky
                </h2>
                <p className="text-sm">
                  Tyto pásky jsou nejčastěji používány pro běžné lepení papíru,
                  kartonu, plastu a lehkých předmětů. Mají obvykle akrylátovou
                  lepicí vrstvu, která poskytuje střední adhezní sílu. Naše
                  lepící pásky používají lepidlo HOLTMELT, takže jsou velmi
                  pevné.
                </p>
                <img
                  className="mx-auto mt-5 rounded-xl hover:scale-110 transition duration-300"
                  src={ObecnePasky}
                  alt="Obecné lepící pásky"
                />
              </div>
            </div>
            <div className="grid lg:grid-cols-4 gap-16 md:gap-20 lg:gap-24 text-center mt-32">
              <div className="col-span-1">
                <div>
                  <img
                    className="w-10 md:w-14 lg:w-16 mx-auto mt-[-3rem] hover:scale-125 transition duration-300"
                    src={Easy}
                    alt="Snadné použití"
                  />
                </div>
                <h3 className="text-[#BB1F24] text-sm md:text-md lg:text-lg lg:mb-4 lg:mt-4 md:mb-3 md:mt-3 mb-2 mt-2">
                  Snadné použití
                </h3>
                <p className="text-sm md:text-md lg-text-lg">
                  Lepící pásky jsou jednoduché na použití. Stačí je odrolit a
                  přilepit k požadovaným povrchům. Nemusíte se zabývat složitými
                  postupy jako při použití lepidel nebo svařování.
                </p>
              </div>
              <div className="col-span-1">
                <div>
                  <img
                    className="w-10 md:w-14 lg:w-16 mx-auto mt-[-3rem] hover:scale-125 transition duration-300"
                    src={Speed}
                    alt="Rychlost a efektivita"
                  />
                </div>
                <h3 className="text-[#BB1F24] text-sm md:text-md lg:text-lg lg:mb-4 lg:mt-4 md:mb-3 md:mt-3 mb-2 mt-2">
                  Rychlost a efektivita
                </h3>
                <p className="text-sm md:text-md lg-text-lg">
                  Lepení pomocí lepících pásek je rychlé a efektivní.
                  Nepotřebujete čekat na vyschnutí nebo zatvrzení, jako je tomu
                  u lepidel. Přilepené materiály se okamžitě drží pohromadě.
                </p>
              </div>
              <div className="col-span-1">
                <div>
                  <img
                    className="w-10 md:w-14 lg:w-16 mx-auto mt-[-3rem] hover:scale-125 transition duration-300"
                    src={Moisture2}
                    alt="Transparentnost"
                  />
                </div>
                <h3 className="text-[#BB1F24] text-sm md:text-md lg:text-lg lg:mb-4 lg:mt-4 md:mb-3 md:mt-3 mb-2 mt-2">
                  Odolnost vůči vlhkosti
                </h3>
                <p className="text-sm md:text-md lg-text-lg">
                  Některé naše lepící pásky jsou odolné vůči vodě, vlhkosti nebo
                  extrémním teplotám. Mohou tedy být použity v prostředích s
                  vysokou vlhkostí nebo v blízkosti tepelných zdrojů.
                </p>
              </div>
              <div className="col-span-1">
                <div>
                  <img
                    className="w-10 md:w-14 lg:w-16 mx-auto mt-[-3rem] hover:scale-125 transition duration-300"
                    src={Demontaz}
                    alt="Snadná demontáž"
                  />
                </div>
                <h3 className="text-[#BB1F24] text-sm md:text-md lg:text-lg lg:mb-4 lg:mt-4 md:mb-3 md:mt-3 mb-2 mt-2">
                  Snadná demontáž
                </h3>
                <p className="text-sm md:text-md lg-text-lg">
                  Některé lepící pásky jsou navrženy tak, aby umožnily relativně
                  snadnou demontáž a odstranění bez poškození povrchu. To je
                  výhodné při dočasných nebo přechodných aplikacích.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Pasky;
