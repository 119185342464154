import React from "react";
import Navbar from "../components/Navbar.jsx";
import Footer from "../components/Footer.jsx";
import BlockKontakt from "../components/BlockKontakt.jsx";

function Kontakt() {
  return (
    <div>
      <Navbar />
      <BlockKontakt />
      <Footer />
    </div>
  );
}

export default Kontakt;
