import React from "react";
import Flexibility from "../assets/flexibility.png";
import Moisture from "../assets/moisture.png";
import Transparency from "../assets/transparency.png";
import Money from "../assets/money.png";
import Rucni from "../assets/rucni-fixacni-folie2.png";
import Strojni from "../assets/strojni-fixacni-folie2.png";
import Pripravujeme from "../assets/pripravujeme.png";
import Environment from "../assets/environment.png";
import Identification from "../assets/identification.png";
import Protection from "../assets/protection.png";
import Storage from "../assets/storage.png";
import BublinkovaFolie from "../assets/bublinkovafolie.png";
import KartonoveObaly from "../assets/kartonoveobaly.png";
import LepenkoveRole from "../assets/lepenkoverole.png";

const Dalsi = () => {
  var censoredElements = document.querySelectorAll(".censored-tm");
  censoredElements.forEach(function (element) {
    element.addEventListener("copy", function (e) {
      e.preventDefault();
    });
  });

  return (
    <div
      id="dalsi"
      className="w-full lg:h-screen text-center py-8 text-white px-4"
    >
      <div>
        <div className="max-w-[1240px] mx-auto">
          <div className="my-4 text-center">
            <div>
              <h1 className="text-white md:text-5xl sm:text-4xl text-3xl font-bold py-2">
                Další obalové technologie
              </h1>
              <p className="text-sm md:text-md lg:text-lg">
                Jejich hlavním cílem je poskytnout ochranu a prezentaci výrobků,
                zajišťovat jejich bezpečnost během skladování, přepravy a
                prodeje, a také přispívat k estetickému a funkčnímu designu.
                Přes smluvní partnery dokážeme dodat různé obaly. Mezi ně patří
                bublinkové fólie, kartonové obaly a lepenkové role.
              </p>
            </div>
            <div className="grid lg:grid-cols-3 gap-10 md:gap-20 lg:gap-40 text-center mt-8">
              <div className="col-span-1">
                <h2 className="text-[#BB1F24] text-sm md:text-lg lg:text-xl font-bold">
                  Bublinkové fólie
                </h2>
                <p className="text-sm">
                  Bublinkové folie jsou tenké průhledné plastové materiály s
                  malými vzduchovými bublinami, které chrání předměty před
                  nárazy a poškrábáním. Často se používají při balení křehkých a
                  citlivých věcí jako sklo, elektronika nebo porcelán. Jsou také
                  snadno manipulovatelné.
                </p>
                <img
                  className="mx-auto mt-5 rounded-xl hover:scale-110 transition duration-300"
                  src={BublinkovaFolie}
                  alt="Bublinkové fólie"
                />
              </div>
              <div className="col-span-1">
                <h2 className="text-[#BB1F24] text-sm md:text-lg lg:text-xl font-bold">
                  Kartonové obaly
                </h2>
                <p className="text-sm">
                  Kartonové obaly jsou vyrobeny z pevného a odolného materiálu
                  zvaného karton. Jsou to pružné a lehké obaly, které slouží k
                  ochraně a přepravě různých předmětů. Kartonové obaly jsou
                  široce využívány v logistice a obchodním prostředí pro balení
                  a skladování produktů.
                </p>
                <img
                  className="mx-auto mt-5 rounded-xl hover:scale-110 transition duration-300"
                  src={KartonoveObaly}
                  alt="Kartonové obaly"
                />
              </div>
              <div className="col-span-1">
                <h2 className="text-[#BB1F24] text-sm md:text-lg lg:text-xl font-bold">
                  Lepenkové role
                </h2>
                <p className="text-sm">
                  Lepenkové role jsou dlouhé válečky vyrobené z lepenky, což je
                  pevný papírový materiál. Tyto role mají typicky válcový tvar a
                  jsou používány balení, uzavírání krabic nebo posilování obalů.
                  Lepenkové role jsou snadno manipulovatelné díky své formě na
                  cívce.
                </p>
                <img
                  className="mx-auto mt-5 rounded-xl hover:scale-110 transition duration-300"
                  src={LepenkoveRole}
                  alt="Lepenkové role"
                />
              </div>
            </div>
            <div className="grid lg:grid-cols-4 gap-16 md:gap-20 lg:gap-24 text-center mt-32">
              <div className="col-span-1">
                <div>
                  <img
                    className="w-10 md:w-14 lg:w-16 mx-auto mt-[-3rem] hover:scale-125 transition duration-300"
                    src={Protection}
                    alt="Ochrana výrobku"
                  />
                </div>
                <h3 className="text-[#BB1F24] text-sm md:text-md lg:text-lg lg:mb-4 lg:mt-4 md:mb-3 md:mt-3 mb-2 mt-2">
                  Ochrana výrobku
                </h3>
                <p className="text-sm md:text-md lg-text-lg">
                  Všechny typy obalů slouží k ochraně výrobku před poškozením,
                  vlhkostí, světlem, znečištěním a dalšími vnějšími vlivy.
                  Pomáhají udržovat kvalitu výrobku a zajišťují, že dorazí ke
                  spotřebiteli v optimálním stavu.
                </p>
              </div>
              <div className="col-span-1">
                <div>
                  <img
                    className="w-10 md:w-14 lg:w-16 mx-auto mt-[-3rem] hover:scale-125 transition duration-300"
                    src={Storage}
                    alt="Manipulace a skladování"
                  />
                </div>
                <h3 className="text-[#BB1F24] text-sm md:text-md lg:text-lg lg:mb-4 lg:mt-4 md:mb-3 md:mt-3 mb-2 mt-2">
                  Manipulace a skladování
                </h3>
                <p className="text-sm md:text-md lg-text-lg">
                  Všechny typy obalů usnadňují manipulaci s výrobky a přispívají
                  k pohodlnějšímu a efektivnějšímu používání, skladování,
                  přepravě a prodeji. Primární obaly jsou snadno otevíratelné a
                  sekundární obaly usnadňují skladování výrobků.
                </p>
              </div>
              <div className="col-span-1">
                <div>
                  <img
                    className="w-10 md:w-14 lg:w-16 mx-auto mt-[-3rem] hover:scale-125 transition duration-300"
                    src={Identification}
                    alt="Identifikace a informace"
                  />
                </div>
                <h3 className="text-[#BB1F24] text-sm md:text-md lg:text-lg lg:mb-4 lg:mt-4 md:mb-3 md:mt-3 mb-2 mt-2">
                  Identifikace a informace
                </h3>
                <p className="text-sm md:text-md lg-text-lg">
                  Všechny typy obalů umožňují umístění informací o výrobku,
                  včetně názvu, značky, popisu, instrukcí a varování. Tím se
                  spotřebitelům poskytuje možnost identifikovat výrobek a získat
                  důležité informace před použitím.
                </p>
              </div>
              <div className="col-span-1">
                <div>
                  <img
                    className="w-10 md:w-14 lg:w-16 mx-auto mt-[-3rem] hover:scale-125 transition duration-300"
                    src={Environment}
                    alt="Environmentální ohledy"
                  />
                </div>
                <h3 className="text-[#BB1F24] text-sm md:text-md lg:text-lg lg:mb-4 lg:mt-4 md:mb-3 md:mt-3 mb-2 mt-2">
                  Environmentální ohledy
                </h3>
                <p className="text-sm md:text-md lg-text-lg">
                  Všechny typy obalů mohou být navrženy tak, aby minimalizovaly
                  negativní dopady na životní prostředí. Například použití
                  recyklovatelných nebo biologicky rozložitelných materiálů a
                  optimalizace velikosti obalů.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Dalsi;
