import React from "react";
import Woman from "../assets/woman.png";
import Castorea from "../assets/castorea.png";

const BlockKontakt = () => {
  return (
    <div>
      <div className="w-full text-center py-16 bg-white px-4">
        <div className="max-w-[1240px] mx-auto">
          <div className="my-4 text-center">
            <div>
              <h1 className="md:text-4xl sm:text-3xl text-2xl font-bold py-2">
                Kontaktujte nás
              </h1>
              <p className="mt-3">
                Pokud máte{" "}
                <span className="text-[#BB1F24]">jakékoli dotazy</span>,{" "}
                <span className="text-[#BB1F24]">přípomínky</span> nebo{" "}
                <span className="text-[#BB1F24]">zájem o spolupráci</span>,
                neváhejte nás kontaktovat prostřednictvím níže{" "}
                <span className="text-[#BB1F24]">uvedených informací</span>.
                Jsme tu, abychom vám poskytli veškerou potřebnou pomoc a
                odpovědi. Vaše{" "}
                <span className="text-[#BB1F24]">zpětná vazba</span> je pro nás{" "}
                <span className="text-[#BB1F24]">důležitá</span> a rádi vám
                pomůžeme <span className="text-[#BB1F24]">s čímkoli</span>, co
                potřebujete.
              </p>
            </div>
          </div>
          <hr></hr>
          <div>
            <div>
              <h1 className="mt-12 text-lg md:text-xl lg:text-2xl font-bold py-2 hover:scale-105 transition duration-300">
                Členové týmu
              </h1>
              <p className="mt-3">
                Zde můžete vidět <span className="text-[#BB1F24]">náš tým</span>
                . Máte možnost se s ním rovnou{" "}
                <span className="text-[#BB1F24]">spojit</span> a{" "}
                <span className="text-[#BB1F24]">navázat kontakt</span>.
              </p>
            </div>
            <div className="w-full py-[1rem] px-4 bg-white">
              <div className="max-w-[1240px] mx-auto flex justify-center">
                {" "}
                {/* Update grid to flex and add justify-center */}
                <div className="w-full max-w-sm shadow-xl flex flex-col p-4 my-4 rounded-lg hover:scale-105 duration-300 py-24">
                  {" "}
                  {/* Adjust max-width and add w-full class */}
                  <img
                    className="w-20 mx-auto mt-[-3rem] bg-white"
                    src={Woman}
                    alt="/"
                  />
                  <p className="text-center mt-6 text-xl md:text-2xl lg:text-4xl font-bold">
                    Kateřina Kramná
                  </p>
                  <div className="text-center font-medium">
                    <p className="py-2 mx-8 mt-8">Sales Manager</p>
                    <p className="py-2 mx-8">+420 604 990 286</p>
                    <p className="py-2 mx-8">kramna@castorea.cz</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <hr></hr>
          <div class="mt-6">
            <div>
              <h1 className="mt-12 text-lg md:text-xl lg:text-2xl font-bold py-2 hover:scale-105 transition duration-300">
                Informace
              </h1>
              <p className="mb-5">
                Zde najdete{" "}
                <span className="text-[#BB1F24]">veškere ostatní</span> potřebné
                informace.
              </p>
            </div>
            <div>
              {" "}
              <ul>
                <li class="text-gray-600">Zámecká 160</li>
                <li class="text-gray-600">747 33 Opava</li>
                <li class="text-gray-600">IČ: 077 34 221</li>
                <li class="text-gray-600">
                  <a href="mailto:info@castorea.cz">info@castorea.cz</a>
                </li>
                <li class="text-gray-600">
                  <a href="http://www.castorea.cz">www.castorea.cz</a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BlockKontakt;
