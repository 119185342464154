import React from "react";
import { FaFacebookSquare, FaInstagram } from "react-icons/fa";
import { AiOutlineMail } from "react-icons/ai";
import Castorea from "../assets/castorea.png";

const Footer = () => {
  return (
    <div className="max-w-[1240px] mx-auto py-16 px-4 grid lg:grid-cols-3 gap-8 text-gray-300">
      <div>
        <div className="flex items-center">
          <img className="w-[50px]" src={Castorea} alt="Castorea" />
          <h1 className="text-3xl font-bold text-white ml-3">CASTOREA</h1>
        </div>
        <p className="py-4">
          Před každou cenovou nabídkou pečlivě analyzujeme spotřebu obalových
          materiálů a provádíme technické zkoušky, aby bylo srovnání s našimi
          výrobky co nejobjektivnější.
        </p>
      </div>
      <div className="lg:col-span-2 flex justify-center gap-16 mt-6">
        <div className=" m-2">
          <h6 className="font-medium text-gray-400">Kontakt</h6>
          <ul>
            <li className="py-2 text-sm">
              <a
                href="/kontakt"
                target="_blank"
                className="hover:text-[#BB1F24]"
              >
                E-mail
              </a>
            </li>
            <li className="py-2 text-sm">
              <a
                href="/kontakt"
                target="_blank"
                className="hover:text-[#BB1F24]"
              >
                Mobil
              </a>
            </li>
            <li className="py-2 text-sm">
              <a
                href="/kontakt"
                target="_blank"
                className="hover:text-[#BB1F24]"
              >
                Mapa
              </a>
            </li>
          </ul>
        </div>
        <div className=" m-2">
          <h6 className="font-medium text-gray-400">Stránka</h6>
          <ul>
            <li className="py-2 text-sm">
              <a href="/" target="_blank" className="hover:text-[#BB1F24]">
                Úvod
              </a>
            </li>
            <li className="py-2 text-sm">
              <a
                href="/produkty"
                target="_blank"
                className="hover:text-[#BB1F24]"
              >
                Produkty
              </a>
            </li>
            <li className="py-2 text-sm">
              <a
                href="/pouziti"
                target="_blank"
                className="hover:text-[#BB1F24]"
              >
                Použití
              </a>
            </li>
            <li className="py-2 text-sm">
              <a href="/o-nas" target="_blank" className="hover:text-[#BB1F24]">
                O nás
              </a>
            </li>
          </ul>
        </div>
        <div className=" m-2">
          <h6 className="font-medium text-gray-400">Ostatní</h6>
          <ul>
            <li className="py-2 text-sm">
              <a
                href="/cookies"
                target="_blank"
                className="hover:text-[#BB1F24]"
              >
                Zásady použití cookies
              </a>
            </li>
            <li className="py-2 text-sm">
              <a href="/gdpr" target="_blank" className="hover:text-[#BB1F24]">
                Zásady ochrany osobních údajů
              </a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default Footer;
