import React from "react";

const BlockOnas = () => {
  return (
    <div className="w-full bg-white py-16 px-4" id="analytics">
      <div className="max-w-[1240px] mx-auto grid md:grid-cols-5">
        <div className="col-start-2 col-span-3 flex flex-col justify-center">
          <div>
            <h1 className="text-center font-dmserif text-3xl font-bold mb-10 ">
              Zásady ochrany osobních údajů
            </h1>
            <div className="text-lg">
              <h3 className="bold text-red-500">I. Základní ustanovení</h3>
              <p className="mb-2">
                1. Správcem osobních údajů podle čl. 4 bod 7 nařízení Evropského
                parlamentu a Rady (EU) 2016/679 o ochraně fyzických osob v
                souvislosti se zpracováním osobních údajů a o volném pohybu
                těchto údajů (dále jen: „GDPR”). IČ 077 34 221 se sídlem Zámecká
                160. (dále jen: „správce“).
              </p>
              <p className="mb-2">
                2. Kontaktní údaje správce jsou{" "}
                <a className="bold text-red-900" href="/kontakt">
                  zde
                </a>
                .
              </p>
              <p className="mb-2">
                3. Osobními údaji se rozumí veškeré informace o identifikované
                nebo identifikovatelné fyzické osobě; identifikovatelnou
                fyzickou osobou je fyzická osoba, kterou lze přímo či nepřímo
                identifikovat, zejména odkazem na určitý identifikátor,
                například jméno, identifikační číslo, lokační údaje, síťový
                identifikátor nebo na jeden či více zvláštních prvků fyzické,
                fyziologické, genetické, psychické, ekonomické, kulturní nebo
                společenské identity této fyzické osoby.
              </p>
              <h3 className="bold text-red-500">
                II. Zdroje a kategorie zpracovávaných osobních údajů
              </h3>
              <p className="mb-2">
                1. Správce zpracovává osobní údaje, které jste mu poskytl/a nebo
                osobní údaje, které správce získal na základě plnění Vaší
                objednávky.
              </p>
              <p className="mb-2">
                2. Správce zpracovává Vaše identifikační a kontaktní údaje a
                údaje nezbytné pro plnění smlouvy.
              </p>
              <h3 className="bold text-red-500">
                III. Zákonný důvod a účel zpracování osobních údajů
              </h3>
              <p className="mb-2">
                1. Zákonným důvodem zpracování osobních údajů je plnění smlouvy
                mezi Vámi a správcem podle čl. 6 odst. 1 písm. b) GDPR,
                oprávněný zájem správce na poskytování přímého marketingu
                (zejména pro zasílání obchodních sdělení a newsletterů) podle
                čl. 6 odst. 1 písm. f) GDPR, Váš souhlas se zpracováním pro
                účely poskytování přímého marketingu (zejména pro zasílání
                obchodních sdělení a newsletterů) podle čl. 6 odst. 1 písm. a)
                GDPR ve spojení s § 7 odst. 2 zákona č. 480/2004 Sb., o
                některých službách informační společnosti v případě, že nedošlo
                k objednávce zboží nebo služby.
              </p>
              <p className="mb-2">
                2. Účelem zpracování osobních údajů je vyřízení Vaší objednávky
                a výkon práv a povinností vyplývajících ze smluvního vztahu mezi
                Vámi a správcem; při objednávce jsou vyžadovány osobní údaje,
                které jsou nutné pro úspěšné vyřízení objednávky (jméno a
                adresa, kontakt), poskytnutí osobních údajů je nutným požadavkem
                pro uzavření a plnění smlouvy, bez poskytnutí osobních údajů
                není možné smlouvu uzavřít či jí ze strany správce plnit,
                zasílání obchodních sdělení a činění dalších marketingových
                aktivit.
              </p>
              <p className="mb-2">
                3. Ze strany správce nedochází k automatickému individuálnímu
                rozhodování ve smyslu čl. 22 GDPR. S takovým zpracováním jste
                poskytl/a svůj výslovný souhlas.
              </p>
              <h3 className="bold text-red-500">IV. Doba uchovávání údajů</h3>
              <p className="mb-2">
                1. Správce uchovává osobní údaje po dobu nezbytnou k výkonu práv
                a povinností vyplývajících ze smluvního vztahu mezi Vámi a
                správcem a uplatňování nároků z těchto smluvních vztahů (po dobu
                15 let od ukončení smluvního vztahu). po dobu, než je odvolán
                souhlas se zpracováním osobních údajů pro účely marketingu,
                nejdéle 5 let, jsou-li osobní údaje zpracovávány na základě
                souhlasu.
              </p>
              <p className="mb-2">
                2. Po uplynutí doby uchovávání osobních údajů správce osobní
                údaje vymaže.
              </p>
              <h3 className="bold text-red-500">
                V. Příjemci osobních údajů (subdodavatelé správce)
              </h3>
              <p className="mb-2">
                1. Příjemci osobních údajů jsou osoby podílející se na dodání
                zboží / služeb / realizaci plateb na základě smlouvy, podílející
                se na zajištění provozu služeb, zajišťující marketingové služby.
              </p>
              <p className="mb-2">
                2. Správce nemá v úmyslu předat osobní údaje do třetí země (do
                země mimo EU) nebo mezinárodní organizaci. Příjemci osobních
                údajů ve třetích zemích jsou poskytovatelé mailingových služeb /
                cloudových služeb.
              </p>
              <h3 className="bold text-red-500">VI. Vaše práva</h3>
              <p className="mb-2">
                1. Za podmínek stanovených v GDPR máte právo na přístup ke svým
                osobním údajům dle čl. 15 GDPR, právo opravu osobních údajů dle
                čl. 16 GDPR, popřípadě omezení zpracování dle čl. 18 GDPR. právo
                na výmaz osobních údajů dle čl. 17 GDPR. právo vznést námitku
                proti zpracování dle čl. 21 GDPR a právo na přenositelnost údajů
                dle čl. 20 GDPR. právo odvolat souhlas se zpracováním písemně
                nebo elektronicky na adresu nebo email správce uvedený v čl. III
                těchto podmínek.
              </p>
              <p className="mb-2">
                2. Dále máte právo podat stížnost u Úřadu pro ochranu osobních
                údajů v případě, že se domníváte, že bylo porušeno Vaší právo na
                ochranu osobních údajů.
              </p>
              <h3 className="bold text-red-500">
                VII. Podmínky zabezpečení osobních údajů
              </h3>
              <p className="mb-2">
                1. Správce prohlašuje, že přijal veškerá vhodná technická a
                organizační opatření k zabezpečení osobních údajů.
              </p>
              <p className="mb-2">
                2. Správce přijal technická opatření k zabezpečení datových
                úložišť a úložišť osobních údajů v listinné podobě
              </p>
              <p className="mb-2">
                3. Správce prohlašuje, že k osobním údajům mají přístup pouze
                jím pověřené osoby.
              </p>
              <h3 className="bold text-red-500">VIII. Závěrečná ustanovení</h3>
              <p className="mb-2">
                1. Odesláním objednávky z internetového objednávkového formuláře
                potvrzujete, že jste seznámen/a s podmínkami ochrany osobních
                údajů a že je v celém rozsahu přijímáte.
              </p>
              <p className="mb-2">
                2. S těmito podmínkami souhlasíte zaškrtnutím souhlasu
                prostřednictvím internetového formuláře. Zaškrtnutím souhlasu
                potvrzujete, že jste seznámen/a s podmínkami ochrany osobních
                údajů a že je v celém rozsahu přijímáte.
              </p>
              <p className="mb-2">
                3. Správce je oprávněn tyto podmínky změnit. Novou verzi
                podmínek ochrany osobních údajů zveřejní na svých internetových
                stránkách, případně Vám zašle novou verzi těchto podmínek na
                e-mailovou adresu, kterou jste správci poskytl/a.
              </p>
              <p className="mt-16">
                Tyto podmínky nabývají účinnosti dnem 25.5.2018.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BlockOnas;
