import React, { useState } from "react";
import { AiOutlineClose, AiOutlineMenu } from "react-icons/ai";
import Castorea from "../assets/castorea.png";

const Navbar = () => {
  const [nav, setNav] = useState(false);

  const handleNav = () => {
    setNav(!nav);
  };

  return (
    <div className="flex justify-between items-center h-24 max-w-[1240px] mx-auto px-4 text-white">
      <a href="/">
        <div className="flex items-center hover:scale-105 transition duration-300">
          <img className="w-[50px]" src={Castorea} alt="Castorea" />
          <h1 className="text-3xl font-bold text-white ml-3">CASTOREA</h1>
        </div>
      </a>
      <ul className="hidden md:flex">
        <li className="p-4">
          <a href="/" className="hover:text-red-500">
            Úvod
          </a>
        </li>
        <li className="p-4">
          <a href="/produkty" className="hover:text-red-500">
            Produkty
          </a>
        </li>
        <li className="p-4">
          <a href="/pouziti" className="hover:text-red-500">
            Použití
          </a>
        </li>
        <li className="p-4 whitespace-nowrap">
          <a href="/o-nas" className="hover:text-red-500">
            O nás
          </a>
        </li>
        <li className="p-4">
          <a href="/kontakt" className="hover:text-red-500">
            Kontakt
          </a>
        </li>
      </ul>
      <div onClick={handleNav} className="block md:hidden">
        {!nav ? <AiOutlineMenu size={20} /> : <AiOutlineClose size={20} />}
      </div>
      <div
        id="dropdown-navbar-id"
        className={
          !nav
            ? "fixed left-[-100%]"
            : "fixed left-0 top-0 w-[60%] h-full border-r border-r-gray-900 bg-[#000300] ease-in-out duration-500"
        }
      >
        <div className="flex items-center m-3">
          <img className="w-[50px]" src={Castorea} alt="Castorea" />
          <h1 className="text-3xl font-bold text-white ml-3">CASTOREA</h1>
        </div>
        <ul className="uppercase p-4">
          <li className="p-4 border-b border-gray-600">
            <a href="/" className="hover:text-red-500">
              Úvod
            </a>
          </li>

          <li className="p-4 border-b border-gray-600">
            <a href="/produkty" className="hover:text-red-500">
              Produkty
            </a>
          </li>

          <li className="p-4 border-b border-gray-600">
            <a href="/pouziti" className="hover:text-red-500">
              Použití
            </a>
          </li>

          <li className="p-4 border-b border-gray-600 whitespace-nowrap">
            <a href="/o-nas" className="hover:text-red-500">
              O nás
            </a>
          </li>

          <li className="p-4">
            <a href="/kontakt" className="hover:text-red-500">
              Kontakt
            </a>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default Navbar;
