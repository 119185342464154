import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Uvod from "./pages/Uvod.jsx";
import Produkty from "./pages/Produkty.jsx";
import Pouziti from "./pages/Pouziti.jsx";
import Onas from "./pages/Onas.jsx";
import Kontakt from "./pages/Kontakt.jsx";
import Cookies from "./pages/Cookies.jsx";
import Gdpr from "./pages/Gdpr.jsx";

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Uvod />} />
        <Route path="/produkty" element={<Produkty />} />
        <Route path="/pouziti" element={<Pouziti />} />
        <Route path="/o-nas" element={<Onas />} />
        <Route path="/kontakt" element={<Kontakt />} />
        <Route path="/cookies" element={<Cookies />} />
        <Route path="/gdpr" element={<Gdpr />} />
      </Routes>
    </Router>
  );
}

export default App;
