import React from "react";
import { useEffect, useRef, useState } from "react";
import Transport from "../assets/transport.png";
import { useInView } from "react-intersection-observer";

const Analytics = () => {
  const [inView, setInView] = useState(false);
  const ref = useRef(null);

  useEffect(() => {
    const handleScroll = () => {
      const { top } = ref.current.getBoundingClientRect();
      const windowHeight =
        window.innerHeight || document.documentElement.clientHeight;
      if (top <= windowHeight * 0.9) {
        setInView(true);
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const containerClassName = `transition-transform duration-1000 ease-in-out ${
    inView ? "scale-x-100" : "scale-x-0"
  }`;

  return (
    <div className="w-full bg-white py-16 px-4" id="analytics">
      <div
        className={`${containerClassName} transform-gpu origin-left`}
        ref={ref}
      >
        <div className="max-w-[1240px] mx-auto grid md:grid-cols-2">
          <img
            className="w-[500px] mx-auto my-4"
            src={Transport}
            alt="Laptop"
          />
          <div className="flex flex-col justify-center">
            <p className="text-[#BB1F24] uppercase font-bold md:mx-0 mx-auto">
              Obalová řešení pro stabilitu nákladu
            </p>
            <h1 className="md:mx-0 mx-auto md:text-4xl sm:text-3xl text-2xl font-bold py-2">
              Ocenění v mezinárodní soutěži
            </h1>
            <p className="md:mx-0 mx-auto md:text-left text-center">
              Díky intenzivnímu výzkumu a vývoji technologie výroby speciálních
              fixačních fólií se nám podařilo dosáhnout úspěchu. Projekt{" "}
              <i>MOVE IT SAFELY</i> vyhrál mezinárodní soutěž pod záštitou
              předního globálního lídra v chemii a plastech, společnosti{" "}
              <i>Dow Chemical</i>. Úsilí, které jsme do vývoje vložili, se
              opravdu vyplatilo!
            </p>
            <a
              href="https://client.dow.com/packagingmoveitsafely"
              rel="noopener noreferrer"
              target="_blank"
              className="text-center bg-[#BB1F24] text-white w-[200px] rounded-md font-medium my-6 md:mx-0 mx-auto py-3 hover:scale-105 transition duration-300"
            >
              Více informací zde
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Analytics;
