import React from "react";
import { useEffect, useRef, useState } from "react";

const Newsletter = () => {
  const [inView, setInView] = useState(false);
  const ref = useRef(null);

  useEffect(() => {
    const handleScroll = () => {
      const { top } = ref.current.getBoundingClientRect();
      const windowHeight =
        window.innerHeight || document.documentElement.clientHeight;
      if (top <= windowHeight * 0.9) {
        setInView(true);
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const containerClassName = `transition-transform duration-1000 ease-in-out ${
    inView ? "scale-x-100" : "scale-x-0"
  }`;

  const [showTable, setShowTable] = useState(false);

  const handleButtonClick = () => {
    setShowTable(!showTable); // Toggle the value of showTable
  };

  return (
    <div className="w-full text-center py-16 text-white px-4">
      <div
        className={`${containerClassName} transform-gpu origin-right`}
        ref={ref}
      >
        <div className="max-w-[1240px] mx-auto">
          <div className="my-4 text-center">
            <div>
              <h1 className="md:text-4xl sm:text-3xl text-2xl font-bold py-2">
                Jaké porovnávací metody používáme?
              </h1>
              <p>
                Zastaralou a ekonomicky nešetrnou metodou je porovnání fixační
                ruční a strojní fólie na základě mikronů.
              </p>
              <p className="mt-6">
                Naše firma{" "}
                <span className="text-[#BB1F24]">CASTOREA s.r.o.</span>
                vyrábí speciální fixační fólii, která podporuje novou směrnici{" "}
                <span className="text-[#BB1F24]">Evropské Unie</span> o
                ekologičtějších alternativách plastových výrobků. Naše fólie má
                menší mikrony, což znamená{" "}
                <span className="text-[#BB1F24]">nižší hmotnost</span> a dokonce{" "}
                <span className="text-[#BB1F24]">menší ekologickou zátěž</span>.
                Přesto dokáže plnohodnotně nahradit fólie s vyššími mikrony, což
                ji <span className="text-[#BB1F24]">činí lídrem na trhu</span>{" "}
                mezi fixačními fóliemi. Je vhodná pro všechny druhy balících
                strojů a disponuje UV ochranou, UV stabilizací, barevností,
                paropropustností a odolností proti propíchnutí.
              </p>
            </div>
            <div className="mt-6">
              <button
                onClick={handleButtonClick}
                className="text-center bg-[#BB1F24] text-white w-[200px] rounded-md font-medium my-6 md:mx-0 mx-auto py-3 hover:scale-105 transition duration-300"
              >
                {showTable ? "Skrýt tabulku" : "Zobrazit tabulku"}
              </button>
            </div>
            {showTable && (
              <div className="lg:col-span-3 my-4 text-center md:max-w-[700px] sm:max-w-[300px] mx-auto">
                <table className="min-w-full bg-white border border-gray-300 rounded-xl overflow-hidden">
                  <thead className="bg-gray-100">
                    <tr className="text-black">
                      <th className="py-4 px-6 border-b">Tloušťka fólie</th>
                      <th className="py-4 px-6 border-b">Šířka fólie</th>
                      <th className="py-4 px-6 border-b">Hmotnost 1bm fólie</th>
                      <th className="py-4 px-6 border-b">
                        Celkový návin z 1kg fólie
                      </th>
                    </tr>
                  </thead>
                  <tbody className="text-[#BB1F24]">
                    <tr>
                      <td className="py-4 px-6 border-b">9 µm</td>
                      <td className="py-4 px-6 border-b">500 mm</td>
                      <td className="py-4 px-6 border-b">4.14 g</td>
                      <td className="py-4 px-6 border-b">241.5 bm</td>
                    </tr>
                    <tr>
                      <td className="py-4 px-6 border-b">12 µm</td>
                      <td className="py-4 px-6 border-b">500 mm</td>
                      <td className="py-4 px-6 border-b">5.52 g</td>
                      <td className="py-4 px-6 border-b">181.2 bm</td>
                    </tr>
                    <tr>
                      <td className="py-4 px-6 border-b">15 µm</td>
                      <td className="py-4 px-6 border-b">500 mm</td>
                      <td className="py-4 px-6 border-b">6.90 g</td>
                      <td className="py-4 px-6 border-b">144.9 bm</td>
                    </tr>
                    <tr>
                      <td className="py-4 px-6 border-b">18 µm</td>
                      <td className="py-4 px-6 border-b">500 mm</td>
                      <td className="py-4 px-6 border-b">8.28 g</td>
                      <td className="py-4 px-6 border-b">120.8 bm</td>
                    </tr>
                    <tr>
                      <td className="py-4 px-6 border-b">21 µm</td>
                      <td className="py-4 px-6 border-b">500 mm</td>
                      <td className="py-4 px-6 border-b">9.66 g</td>
                      <td className="py-4 px-6 border-b">103.5 bm</td>
                    </tr>
                    <tr>
                      <td className="py-4 px-6 border-b">24 µm</td>
                      <td className="py-4 px-6 border-b">500 mm</td>
                      <td className="py-4 px-6 border-b">11.04 g</td>
                      <td className="py-4 px-6 border-b">90.6 bm</td>
                    </tr>
                    <tr>
                      <td className="py-4 px-6 border-b">27 µm</td>
                      <td className="py-4 px-6 border-b">500 mm</td>
                      <td className="py-4 px-6 border-b">12.42 g</td>
                      <td className="py-4 px-6 border-b">80.5 bm</td>
                    </tr>
                    <tr>
                      <td className="py-4 px-6 border-b">30 µm</td>
                      <td className="py-4 px-6 border-b">500 mm</td>
                      <td className="py-4 px-6 border-b">13.34 g</td>
                      <td className="py-4 px-6 border-b">72.5 bm</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Newsletter;
